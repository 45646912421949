.collapse-css-transition {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

  > * {
    transition-duration: 300ms;
  }
}

.collapsed-list {
  font-family: Halvar Breitschrift, sans-serif;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  max-width: 1240px;
  padding-bottom: 100px;
  padding-top: 80px;
  @media (max-width: 600px) {
    padding: 30px 10px;
  }

  .faq-list {
    position: relative;
    z-index: 1;
    transition: max-height 700ms ease-out;
    max-height: 650px;
    overflow: hidden;

    .gradiento {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 87px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), white 152%);
      //pointer-events: none;

      }
    }
    .molemp_monatu_sumendadan {
      position: relative;
    }
    .molemp_monatu_sumendadan::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 10;
      height: 100%;
      background: linear-gradient( rgba(255, 255, 255, 0.8), white );
      pointer-events: none;
    }
    .cegarckaba-onemenu-kaquiped {
      overflow-y: scroll;
      background: linear-gradient( rgba(255, 255, 255, 1), white );
      width: 100%;
      position: relative;
      height: 100%;
      line-height: 1.3;
      z-index: 12;
    }

    .faq-list--expand {
      position: relative;
      z-index: 1;
      max-height: 1400px;
      overflow: hidden;
      transition: max-height 700ms ease-in;
      background: none;
    }
  }

  /* Rectangle 454 */

.block {
  margin-bottom: 10px;
  color: #212121;

  .faq-btn {
    min-height: 100px;
    font-family: Halvar Breitschrift, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: #212121;

    button {
      white-space: normal;
    }
  }

}

.btn {
  font-size: 100%;
  background: transparent;
  box-shadow: none;
  width: max-content;
  margin: 0;
  padding: 0 36px;
  text-align: left;
  cursor: pointer;
  height: 65px !important;

  span {
    height: auto !important;
  }
}

.toggle {
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;

  color: white;
  height: 30px!important;
  @media (max-width: 600px) {
    height: auto !important;
  }

  span {
    height: auto !important;
  }
}

.active-toggle-text {
  font-style: normal;
  display: flex;
  align-items: center;
  font-family: Halvar Breitschrift, sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #212121;
  letter-spacing: -0.02em;
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 23px;
  }
}

header {

  margin-bottom: 40px;

  button {
    font-size: 100%;
    background: none;
    padding: 10px;

    + button {
      margin-left: 10px;
    }
  }

}

.collapsed-content {
  padding: 30px 10px 30px;

  p {
    white-space: break-spaces;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.03em;
    color: #212121;
    @media (max-width: 600px) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

button {
  transition: color 280ms;
}

button[disabled] {
  color: #aaa;
}

.icon-down {
  fill: white;
  @media (max-width: 600px) {
    transform: scale(0.7)
  }

  span {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      rect {
        fill: white;

      }
    }
  }

  &.is-open {
    fill: white;
  }
}

.bottom-line {
  width: 100%;
  height: 1px;
  background: #262626;
  opacity: 0.5;
  border-radius: 2px;
}
.btn-group{
  z-index: 2;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn{
    max-width: 262px;
  }
}
