
.footer {
  flex: 0 0 !important;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  height: 75px;
  box-shadow: 0 -2px 4px rgba(55, 29, 199, 0.05);
  font-size: 12px;
  color: #212121;
  background: #FFF;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    height: 1px;
    background: #fff;
    opacity: 0.3;
    border-radius: 2px;
  }

  @media (max-width: 600px) {
    height: auto;
  }

  a {
    color: currentColor;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  > .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1360px;
    @media (max-width: 900px) {
      padding: 0 15px;
    }
    @media (max-width: 1024px) {
      display: block;
      text-align: center;
    }
  }

  .copyright {
    @media (max-width: 1024px) {
      margin-bottom: 20px;
    }
  }

  .contact {
    text-align: right;
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 1024px) {
      justify-content: space-around;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
.terms-of-use{
  &:hover {
    text-decoration: underline;
  }
}
  .socials {
    text-align: center;
    &__list {
      &__link {
        display: inline-block;
        margin: 0 10px;

        a {
          svg {
            width: 20px;
            height: 20px;

            path {
              fill: currentColor;
            }
          }
        }
      }
    }
  }
}
