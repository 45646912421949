

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Regular.eot");
  src: url("../../assets/fonts/Inter-Regular.woff") format("woff"),
  url("../../assets/fonts/Inter-Regular.ttf") format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Medium.woff") format("woff");
  src: url("../../assets/fonts/Inter-Medium.eot"),
  url("../../assets/fonts/Inter-Medium.ttf") format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('../../assets/fonts/inter-v12-latin-300.woff2'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/inter-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/inter-v12-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/inter-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/inter-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/inter-v12-latin-300.svg#Inter') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: "Mersad";
  src: url("../../assets/fonts/Mersad Bold.eot");
  src: url("../../assets/fonts/Mersad Bold.woff") format("woff"),
  url("../../assets/fonts/Mersad Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
