.header {
  width: 100%;
  height: 6.25rem;
  //background: linear-gradient(180deg, #212121 39.84%, rgba(33, 33, 33, 0) 100%);
  background: $black;
  justify-content: space-between;

  position: relative;
  z-index: 20;
  @media screen and (max-width: $mobile-breakpoint) {
    height: 7.125rem;
  }

  *,
  * {
    white-space: nowrap;
  }
}

.header-container {
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  z-index: 5;
  position: relative;

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 0 0;
  }
}

.header__content {
  justify-content: space-between;
  padding: 0 20px;

  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  grid-column-gap: 2rem;
  @media screen and (max-width: $tablet-breakpoint) {
    grid-column-gap: 1rem;
  }
}

.header__logo-wrapper {
  margin: 0 auto 0 0;
}

.header__logo {
  @media screen and (max-width: $mobile-breakpoint) {
    width: 11rem;
  }
}

.header__link {
  color: white;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;

  @media screen and (max-width: 1124px) {
    display: none;
  }
}

.submenu {
  position: relative;
  height: 6.25rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.submenu__name {
  color: white;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;
}

.submenu:hover .submenu__name {
  color: $green;
  cursor: default;
}

.submenu__arrow {
  height: 0.5rem;
  width: 1rem;
  margin-left: 0.75rem;
  transform: rotate(0);
  transition: transform 0.25s ease-in-out;
}

.submenu:hover .submenu__arrow {
  transform: rotate(180deg);
}

.submenu__items {
  width: 27rem;
  position: absolute;

  top: 6.25rem;
  overflow: hidden;
  background: $black;
  box-shadow: 0 6px 10px rgba(37, 37, 37, 0.25), 0 4px 4px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  transition: height 0.25s ease-in-out;

  --items-amount: unset; //passed from html
  height: 0;
  // should be calc(2.875rem * var(--items-amount) + 1.5rem), when hovering .submenu,
  // where 2.875rem = height + margins + some gap
  // and 1.5rem is additional space at top and bottom
  // this needed for equal animation speed for any amount of items inside
  //need for last-child  right: 0; with scss

}


.submenu:hover .submenu__items {
  //see explanation at .submenu__items ^^^
  height: calc(2.875rem * var(--items-amount) + 1.5rem);
}

.submenu__item {
  padding: 1rem 1.25rem;
  color: white;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;
}

.account {
  padding: 0.5rem 1rem;
  background: $lighter-black;
  border: 1px solid white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.account__wallet-logo {
  background: white;
  height: 1.5rem;
  width: 1.5rem;
  object-position: center;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.account__address {
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
  color: #ffffff;
}

.logout {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}

.logout__icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

.logout__text {
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.22em;
  color: #ffffff;
}

.burger-icon {
  display: none;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 2.5rem;
    height: 1rem;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-end;

    position: relative;
  }
}

.burger-icon__line {
  height: 2px;
  background: white;
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
  position: relative;
}

.burger-icon__first-line {
  width: calc(100% / 3);
}

.burger-icon__second-line {
  width: calc((100% / 3) * 2);
}

.burger-icon__third-line {
  width: calc((100% / 3) * 2);
}

.burger-icon_open {
  .burger-icon__first-line {
    width: calc(100% / 3);
    transform: rotate(45deg);
    top: 2px;
    right: -2px;
  }

  .burger-icon__second-line {
    width: 100%;
  }

  .burger-icon__third-line {
    width: calc(100% / 3);
    transform: rotate(-45deg);
    bottom: 2px;
    right: -2px;
  }
}

.wallet-connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  background: $graphitered;

  p {
    color: #ffffff;
    padding-left: 10px;
  }
}

.connect-metamask-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  gap: 13px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px auto;

  padding: 20px 16px !important;
  width: 90%;
  min-height: 66px !important;
  background: #262626;
  p {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    font-family: Aktiv Grotesk Corp;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    text-align: right;
    letter-spacing: 0.09rem;
    text-transform: uppercase;
    color: #ffffff;
  }
}
