.xs-400 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.xs-500 {
  font-family: Halvar Breitschrift, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #9198bb;
}

.s-400 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: 'liga' off;
  color: $black;
}

.s-400-gray {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration-line: underline;
  font-feature-settings: 'liga' off;
  color: $color-gray;
}

.s-500-gray {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $color-gray;
}

.m-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: $white !important;
  @media (max-width: 600px) {
    font-family: Neue Machina, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
}

.m-400 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;

  color: $color-gray;
}

.l-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: $black;
}

.l-500 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: $black;
}

.l-500-white {
  font-family: Proxima Nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.l-700 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;

  color: $green;
}

.xl-400 {
  font-family: Halvar Breitschrift, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.xxl-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: $black;
}

.xxxl-500 {
  font-family: Halvar Breitschrift, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $white;
}
