.layout {
  background: #212121;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .staking_top-left {
    background: url("../../assets/images/staking_top-left.png") no-repeat;
    top: 40px;
    z-index: 0;
    position: absolute;
    left: 0;
    width: 600px;
    height: 600px;
    background-blend-mode: normal, hue, normal, normal;
    mix-blend-mode: lighten;
    @media (max-width: 600px) {
      top: -100px;
      left: -100px;
      transform: scale(0.7)
    }

  }

  .staking_top-right {
    background: url("../../assets/images/staking_top-right.png") no-repeat;
    top: -22%;
    z-index: 0;
    position: absolute;
    right: -270px;
    background-blend-mode: normal, hue, normal, normal;
    mix-blend-mode: lighten;
    width: 1071px;
    height: 1000px;
    transform: scale(0.5);
    @media (max-width: 600px) {
      top: -3%;
      right: -480px;
      transform: scale(0.44) rotate(-3deg);
      height: 600px;
    }

  }
}

.content {
  min-height: 89vh;
  background-color: #212121;
  padding-bottom: 100px;

  .page {
  }
}
.wrapper {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1240px;
  height: auto;
  @media (max-width: 480px) {
    width: 95%;
    flex-direction: column;
  }
}
