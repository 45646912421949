$white: #fff;
$whiteToGray: #f7f7fd;
$black: #212121;
$green-light: #2edb9c;
$color-primary: #4a38ae;
$color-primary-light: #5c49c6;
$color-gray: #9198bb;
$color-blue: #bfc9e0;
$color-gray-light: #cdd7ed;
$red: #a32626;
$graphitered: #262626;
$green: #15d378;
$lighter-black: #262626;
$mobile-breakpoint: 1024px;
$tablet-breakpoint: 1280px;

* {
  --content-width: 1280px;
}

@media screen and (max-width: $tablet-breakpoint) {
  * {
    --content-width: 100%;
  }
}
