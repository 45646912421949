.mobile-display-none {
  max-width: 160px;
  @media (max-width: 480px) {
    display: none;
  }
}

.w-100 {
  width: 100% !important;
  white-space: pre-wrap;
}