.not-supported {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;

  color: #FFFFFF;
  background: #A32626;

  .switch-text {
    cursor: pointer;
    font-weight: bold;
    font-style: oblique;
  }

}
