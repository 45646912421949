@keyframes swirl-in-fwd {
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

.swirl-in-fwd {
  animation: swirl-in-fwd 0.6s ease-out both;
}

@keyframes swirl-out-bck {
  0% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
}

.swirl-out-bck {
  animation: swirl-out-bck 0.6s ease-in both;
}

.skeleton-box {
  display: inline-block !important;
  align-items: center;
  justify-content: center;
  height: 25px !important;
  width: 100px;
  position: relative !important;
  overflow: hidden !important;
  background-color: #FFFFFF !important;

  &::after {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%) !important;
    background-image: linear-gradient(
                    90deg,
                    rgba(#cc2828, 0) 0,
                    rgba(#fff, 0.2) 20%,
                    rgba(#fff, 0.5) 60%,
                    rgba(#c94e4e, 0)
    );
    animation: shimmer 2s infinite !important;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%) !important;
    }
  }
}
