.icon-gear::before {
  content: "\e91b";
  color: #CECECE;
}

.icon-open-arrow::before {
  content: "\e91c";
  color: #4D4D4D;
}

.icon-success::before {
  content: "\e919";
  color: #FFF;
}

.icon-fail::before {
  content: "\e91a";
  color: #FFF;
}

.icon-question::before {
  content: "\e918";
  color: #FF586C;
}

.icon-pin::before {
  content: "\e913";
}

.icon-arrow::before {
  content: "\e912";
  color: #CECECE;
}

.icon-filter-validator::before {
  content: "\e900";
  color: #8D8D8D;
}

.icon-log-in::before {
  content: "\e901";
  color: #CECECE;
}

.icon-account::before {
  content: "\e902";
  color: #FFF;
}

.icon-charning::before {
  content: "\e903";
  color: #CECECE;
}

.icon-clock::before {
  content: "\e904";
  color: #CECECE;
}

.icon-close::before {
  content: "\e905";
  color: #FFF;
}

.icon-earphons::before {
  content: "\e906";
  color: #2EC3E9;
}

.icon-galka::before {
  content: "\e907";
  color: #2EC3E9;
}

.icon-iconfile::before {
  content: "\e908";
  color: #CECECE;
}

.icon-loop::before {
  content: "\e909";
  color: #FFF;
}

.icon-marker::before {
  content: "\e90a";
  color: #FFF;
}

.icon-plus-cir::before {
  content: "\e90b";
  color: #2EC3E9;
}

.icon-replace::before {
  content: "\e90c";
  color: #2EC3E9;
}

.icon-schems::before {
  content: "\e90d";
  color: #CECECE;
}

.icon-setting::before {
  content: "\e90e";
  color: #CECECE;
}

.icon-shield-gal::before {
  content: "\e90f";
  color: #CECECE;
}

.icon-shield::before {
  content: "\e910";
  color: #FFAE4F;
}

.icon-trash::before {
  content: "\e911";
  color: #CECECE;
}

.icon-tree1::before {
  content: "\e914";
  color: #FFF;
}

.icon-users1::before {
  content: "\e915";
  color: #CECECE;
}

.icon-vilka1::before {
  content: "\e916";
  color: #CECECE;
}

.icon-warning1::before {
  content: "\e917";
  color: #FF6C58;
}
