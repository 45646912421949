iframe {
  display: none !important;
}

.home-static {
  display: flex;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  @include for_laptop {
    position: relative;
    left: -4vw;
    overflow: auto;
    width: 108vw;
  }
  &-wrapper {
    display: flex;
    align-items: center;

    @include for_laptop {
      flex-direction: column;
      height: 500px;

      background-color: #212121;
    }
  }
}

p, a {
  font-family: Inter, sans-serif;
}

.home-static__leftside {
  display: flex;
  position: relative;
  background-color: #212121;

  align-items: center;
  justify-content: center;
  height: 140vh;

  column-gap: 30px;
  padding: 0 80px;

  border-bottom-right-radius: 400px 1000px;
  border-top-right-radius: 400px 1000px;

  @include for_laptop {
    width: 108vw;

    height: 26vw;
    flex-shrink: 0;
    top: 0vh;
    border-radius: 0;
    column-gap: 15px;
    justify-content: flex-start;
    padding-left: 18vw;
  }
  @include for_phone {
    padding-left: 12vw;
  }
  &-logo {
    @include for_laptop {
      & > svg {
        width: 75px;
      }
    }
  }
  &-text {
    @include for_laptop {
      & > div > svg {
        height: 18px;
        width: 150px;
      }
    }
  }
}

.home-static__rightside {
  display: flex;
  flex-direction: column;

  padding-left: 100px;
  padding-right: 80px;
  height: 100%;
  @include for_laptop {
    position: relative;
    border-top-left-radius: 100% 20%;
    border-top-right-radius: 100% 20%;
    background-color: #fff;
    width: 108vw;

    align-items: flex-start;
    padding-left: 18vw;
  }
  @include for_phone {
    padding-left: 12vw;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
    flex-grow: 1;
    @include for_laptop {
      margin-top: 120px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    max-width: 650px;

    font-weight: 300;
    font-size: 20px;
    line-height: 140%;

    color: #0e0e0e;
  }
  &-botom {
    display: flex;
    align-items: center;
    column-gap: 40px;
    & > a {
      white-space: nowrap;
      color: #0e0e0e;
      &:hover {
        color: #457eff;
      }
    }

    @include for_phone {
      column-gap: 20px;
    }
  }

  &-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    cursor: pointer;
    font-weight: 500;

    width: 151px;
    height: 43px;

    border: 2px solid #0e0e0e;
    border-radius: 24px;
    &:hover {
      border: 2px solid #457eff;
    }
  }
  &-url {
    font-weight: 500;
  }
  &-socials {
    display: flex;
    column-gap: 35px;
    align-items: center;
    margin: 60px 0;
    margin-bottom: 80px;
    cursor: pointer;
  }
}
